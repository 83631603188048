import * as React from "react"
import YourOrder from "../components/pages/yourorder";
import Layout from "../templates/layout";
import FullApp from "../FullApp";
import Panel from "../components/panel";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Panel {...props}>
        <YourOrder />
      </Panel>
    </Layout>
  </FullApp>
);