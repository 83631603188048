import React, {Component} from 'react';
import {Header, Divider, Form, Input, Button, Container, Grid, Icon} from "semantic-ui-react";
import * as actions from "../../actions";
import {connect} from "react-redux";

interface StateProps {
  getInvoiceAddresses: any
  addInvoiceAddress: any
  address: any
}

export function mapStateToProps(state: any): StateProps {
  return {
    getInvoiceAddresses: state.getInvoiceFormData,
    address: state.addInvoiceAddress,
  };
}

interface DispatchProps {
  addInvoiceAddress: typeof actions.addInvoiceAddress,

}

const mapDispatchToProps = {
  addInvoiceAddress: actions.addInvoiceAddress,
};

interface OwnProps {
  id?: string,
  name: string,
  header: string,
  disabled: boolean
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  taxNumber: string,
  country: string,
  city: string,
  zipCode: string,
  street: string,
  streetNumber: string,
  phone: string,
  name: string,
  email: string,
  addressDone: boolean,
}

class Invoice extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      taxNumber: "",
      country: "",
      city: "",
      zipCode: "",
      street: "",
      streetNumber: "",
      phone: "",
      name: "",
      email: "",
      addressDone: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.getInvoiceAddresses !== this.props.getInvoiceAddresses) {
      if (this.props.getInvoiceAddresses) {
        let data = this.state;
        this.props.collect({[this.props.name]: data});
      }
    }
    if (prevProps.address !== this.props.address) {
      if (this.props.address.data) {
        this.setState({
          addressDone: true,
        })
        this.props.isFinished(true);
      }
    }
  }

  handleValueChange(e, {name, value}) {
    this.setState({[name]: value})
  }

  setInvoiceAddress(e) {
    e.preventDefault();
    let formData = {
      tax_number: this.state.taxNumber,
      country: 'Poland',
      city: this.state.city,
      zip_code: this.state.zipCode,
      street: this.state.street,
      street_number: this.state.streetNumber,
      phone: this.state.phone,
      name: this.state.name,
      email: this.state.email,
      code: this.props.code
    }
    this.props.addInvoiceAddress(formData)
  }

  copyDataFromAddress = () =>{
    for (const [key, value] of Object.entries(this.props.copiedAddress)){
      this.setState({
        [key]: value
      })
    }
  }

  editAddress = () => {
    this.setState({
      addressDone: false,
    })
    this.props.isFinished(false);
  }

  handleOnlyNumbers= (e, {name, value}) => {
    if (value.includes("-")){
      value = value.replace("-","");
    }
    if (isNaN(value)) {
      return
    }
    if(value.length <= 5){
      this.handleValueChange(e, {name, value})
    }
  }

  showBetterZipCode = (value) => {
    let array = value.split("");
    if (value.length >= 3) array.splice(2, 0, "-");
    return array.join("");
  }

  render() {
    if (!this.state.addressDone) {
      return (
        <>
          <Header as="h1" textAlign="center" disabled={this.props.disabled}>{this.props.header}</Header>
          <div style={{display: "flex", justifyContent: "center"}}>
            <Button className={"shadow-dark"} id="btn-use-address" compact onClick={() => this.copyDataFromAddress()}>
              <Icon size={"large"} name={"arrow down"}/>
              wykorzystaj dane dostawy
            </Button>
          </div>
          <Form id={this.props.id} onSubmit={this.setInvoiceAddress.bind(this)}>
              <Form.Field
                name={"name"}
                value={this.state.name}
                required
                control={Input}
                label='Nazwa'
                placeholder='Nazwa'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
            <Form.Field
              name={"taxNumber"}
              value={this.state.taxNumber}
              required
              control={Input}
              label='NIP'
              placeholder='NIP'
              onChange={this.handleValueChange.bind(this)}
              disabled={this.props.disabled}
            />
            <Form.Group>
              <Form.Field width={12}
                          name={"street"}
                          value={this.state.street}
                          required
                          control={Input}
                          label='Adres'
                          placeholder='Adres'
                          onChange={this.handleValueChange.bind(this)}
                          disabled={this.props.disabled}
              />
              <Form.Field width={4}
                          name={"streetNumber"}
                          value={this.state.streetNumber}
                          required
                          control={Input}
                          label='Nr domu'
                          placeholder='Nr domu'
                          onChange={this.handleValueChange.bind(this)}
                          disabled={this.props.disabled}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field
                name={"zipCode"}
                value={this.showBetterZipCode(this.state.zipCode)}
                required
                control={Input}
                label='Kod pocztowy'
                placeholder='Kod pocztowy'
                onChange={this.handleOnlyNumbers.bind(this)}
                disabled={this.props.disabled}
              />
              <Form.Field
                name={"city"}
                value={this.state.city}
                required
                control={Input}
                label='Miasto'
                placeholder='Miasto'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field
                name={"phone"}
                value={this.state.phone}
                control={Input}
                label='Numer telefonu'
                placeholder='Numer telefonu'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
              <Form.Field
                name={"email"}
                value={this.state.email}
                control={Input}
                label='Adres e-mail'
                placeholder='Adres e-mail'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
            </Form.Group>
            <Grid textAlign='center'>
              <Grid.Column>
                <Button primary className='shadow-light' id='btn-invoice'>Ustaw dane faktury</Button>
              </Grid.Column>
            </Grid>
          </Form>
        </>
      );
    } else {
      const data = this.props.address.data.vat
      return <div>
        <Header as="h1" textAlign="center" disabled={this.props.disabled}>{this.props.header}</Header>
        <Divider hidden/>
        <Container>
          <Grid celled>
            <Grid.Row columns={3}>
              <Grid.Column>Nazwa:</Grid.Column>
              <Grid.Column>{data.name}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Ulica:</Grid.Column>
              <Grid.Column>{data.street}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Numer domu / lokalu:</Grid.Column>
              <Grid.Column>{data.street_number}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Kod pocztowy:</Grid.Column>
              <Grid.Column>{this.showBetterZipCode(data.zip_code)}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Miasto:</Grid.Column>
              <Grid.Column>{data.city}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Kraj:</Grid.Column>
              <Grid.Column>{data.country}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Email:</Grid.Column>
              <Grid.Column>{data.email}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Telefon:</Grid.Column>
              <Grid.Column>{data.phone}</Grid.Column>
            </Grid.Row>
          </Grid>
          <Button onClick={() => this.editAddress()} floated="right">edytuj adres</Button>
        </Container>
      </div>
    }

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
