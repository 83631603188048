import React, {Component} from 'react';
import {
  Button, Checkbox, Container, Divider, Form, Grid, Header, Image, Input, Message, Radio, Segment, TextArea
} from "semantic-ui-react";
import {t} from "../../i18n";
import Address from "./address";
import * as actions from "../../actions";
import {connect} from "react-redux";
import {navigateTo} from "../../helpers/router";
import _ from "lodash";
import Invoice from "./invoice";
import {toast} from "react-toastify";

const arrow_right = '/img/utils/arrow-right-small.png';
const dpdLogo = '/img/utils/dpd.png';
const box = '/img/box-01.png';
const dict = {
  "payu": "Przelew natychmiastowy, płatność kartą i raty (payU)",
  "bank_transfer": "Tradycyjny przelew bankowy"
}

interface StateProps {
  user: any
  payment: any
  order: any
  promo: any
  details: any
}

export function mapStateToProps(state: any): StateProps {
  return {
    user: state.user,
    order: state.getOrderBeforePayment,
    payment: state.payuPayment,
    promo: state.sendPromoCode,
    details: state.getDetails
  };
}

interface DispatchProps {
  getOrderBeforePayment: typeof actions.getOrderBeforePayment,
  payuPayment: typeof actions.payuPayment,
  sendPromoCode: typeof actions.sendPromoCode,
  getDetails: typeof actions.getDetails,
}

const mapDispatchToProps = {
  getOrderBeforePayment: actions.getOrderBeforePayment,
  payuPayment: actions.payuPayment,
  sendPromoCode: actions.sendPromoCode,
  getDetails: actions.getDetails,
};

type Props = StateProps & DispatchProps;

interface State {
  disabled: boolean,
  value: string,
  paymentMethod: string,
  order: any,
  orderCode: string,
  shipment: string,
  btnDisabled: boolean,
  wantInvoice: boolean,
  helperAddressForInvoice: object,
  promoActive: boolean,
  addressFinished: boolean,
  invoiceFinished: boolean,
  deliveryComment: string,
  loading: boolean
}

class YourOrder extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disabled: true,
      value: "",
      paymentMethod: "payu",
      order: "",
      orderCode: "",
      shipment: "dpd",
      btnDisabled: false,
      wantInvoice: false,
      helperAddressForInvoice: {},
      promoActive: false,
      addressFinished: false,
      invoiceFinished: false,
      deliveryComment: "",
      loading: true,
    }


  }

  componentDidMount(): void {
    let type = localStorage.getItem('order');
    let orderCode = localStorage.getItem('orderCode');
    if(type === 'survey'){
      orderCode = localStorage.getItem('surveyOrderCode');
    }
    if(orderCode){
      let code = btoa(orderCode);
      this.setState({orderCode: orderCode}, () => this.props.getOrderBeforePayment(code));
    } else {
      navigateTo("/checkorder");
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.order !== this.props.order) {
      let order = this.props.order;
      if(order.errors){
        this.setState({loading: false})
      } else if (order.data) {
        this.onOrderChange(order.data);
      }
    }
    if (prevProps.promo !== this.props.promo) {
      let orders = this.props.promo;
      if (orders.data) {
        let activeOrder = orders.data;
        this.onOrderChange(activeOrder);
      }
    }
    if (this.state.order.promo_code && !this.state.promoActive) {
      this.setState({
        promoActive: true
      })
    }
    if (prevProps.payment !== this.props.payment) {
      let payment = this.props.payment;
      if (typeof window !== `undefined`)
        window.location.href = payment.redirectUri;
    }
    if (prevProps.details !== this.props.details) {
      const file = new Blob(
        [this.props.details],
        {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
    }
    if (prevProps.promo !== this.props.promo) {
      if (this.props.promo.data) {
        if (this.props.promo.data.promo_code) {
          this.promoCodeActive()
        }
      }
    }
  }

  promoCodeActive = () => {
    toast.success("Pomyślnie dodano kupon rabatowy!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    this.setState({promoActive: true});
  }

  onOrderChange(activeOrder) {
    if (activeOrder) {
      this.setState({
        order: activeOrder,
        orderCode: activeOrder.code,
        loading: false
      })
    } else {
      this.setState({loading: false})
    }
  }

  enableForm(): void {
    this.setState({disabled: !this.state.disabled})
  }

  handleInputChange(e: any): void {
    this.setState({value: e.target.value});
  }

  handleDeliveryComment = (e) => {
    this.setState({
      deliveryComment: e.target.value
    })
  }

  sendPromo(): void {
    let data = {
      order_code: btoa(this.state.orderCode),
      promo_code: this.state.value
    };

    this.setState({value: ""}, () => this.props.sendPromoCode(data));
  }

  orderPayment() {
    let price = this.findValue('price_full');

    let data = {
      payment: {
        provider: this.state.paymentMethod,
        type: this.findValue('type'),
        price: price,
        units: 1
      },
      delivery: {
        type: "dpd",
        comment: this.state.deliveryComment
      }
    }
    this.props.payuPayment(btoa(this.state.orderCode), data);
  }

  findValue(value) {
    let pm = this.state.paymentMethod;
    let ba = _.find(this.state.order['payment_methods'], {'provider': pm});
    if (ba) {
      return ba[value];
    }
  }

  showInvoice(): void {
    this.setState({
      wantInvoice: !this.state.wantInvoice,
    })
  }

  getDocument() {
    let code = btoa(this.state.orderCode)
    this.props.getDetails(code)
  }

  getHelperAddress = (address) => {
    this.setState({
      helperAddressForInvoice: address
    })
  }

  discountValue = () => {
    if (this.state.order.promo_code) {
      return (Number(this.findValue('from_price_full')) - Number(this.findValue('price_full'))).toFixed(2)
    }
    return "--"
  }

  addressIsFinished = (isFinished) => {
    this.setState({
      addressFinished: isFinished
    })
  }

  invoiceIsFinished = (isFinished) => {
    this.setState({
      invoiceFinished: isFinished
    })
  }

  scrollAndToast(toastContent, scrollOffset){
    toast.info(t(toastContent), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    window.scrollTo({top: scrollOffset, behavior: "smooth"})
  }

  displayButton() {
    if (!this.state.addressFinished) {
      return (
        <Button secondary id='btn-order' className='shadow-dark' floated="right"
                onClick={this.scrollAndToast.bind(this, "Uzupełnij i ustaw dane dostawy", 0 )}>Uzupełnij adres dostawy</Button>
      )
    } else {
      if (!this.state.wantInvoice) {
        return (
          <Button secondary id='btn-order' className='shadow-dark' floated="right"
                  onClick={this.orderPayment.bind(this)}>Zamawiam i płacę</Button>
        )
      } else if (this.state.invoiceFinished) {
        return (
          <Button secondary id='btn-order' className='shadow-dark' floated="right"
                  onClick={this.orderPayment.bind(this)}>Zamawiam i płacę</Button>
        )
      } else {
        return (
          <Button secondary id='btn-order' className='shadow-dark' floated="right"
                  onClick={this.scrollAndToast.bind(this, "Uzupełnij i ustaw dane faktury", 600 )}>Uzupełnij dane faktury</Button>
        )
      }
    }
  }

  orderRedirect(){
    let type = localStorage.getItem('order') ?? 'normal';
    navigateTo(`/order?type=${type}`);
  }


  yourorder() {
    return (
      <Segment basic style={{minHeight: "calc(100vh - 10px)", paddingTop: "150px"}}>
        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <Address id="paymentAddress" name='paymentAddress' header="Adres dostawy" disabled={false}
                         code={btoa(this.state.orderCode)} getHelperAddress={this.getHelperAddress}
                         isFinished={this.addressIsFinished} dbAddress={this.state.order.address}
                />
              </Grid.Column>
            </Grid.Row>
            <Checkbox label={'chcę otrzymać fakturę'} checked={this.state.wantInvoice}
                      onChange={this.showInvoice.bind(this)}/>
            {this.state.wantInvoice && <Grid.Row>
              <Grid.Column>
                <Invoice id="invoiceAddress" name='invoiceAddress' header="Dane do faktury" disabled={false}
                         code={btoa(this.state.orderCode)} copiedAddress={this.state.helperAddressForInvoice}
                         isFinished={this.invoiceIsFinished}
                />
              </Grid.Column>
            </Grid.Row>}
          </Grid>
          <Divider section hidden/>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1" textAlign="center">Metoda dostawy</Header>
                <Form.Field
                  control={Checkbox}
                  disabled
                  checked
                  value='dpd'
                  onClick={() => this.setState({shipment: 'dpd'})}
                  label={<label><Image src={dpdLogo} alt="Logo DPD" inline verticalAlign="middle"/> {this.findValue('price_delivery')} zł</label>}>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <Header as="h4" textAlign="center">Dodatkowe informacje dla dostarczyciela</Header>
                <Form>
                  <Form.Field
                    control={TextArea}
                    rows={3}
                    value={this.state.deliveryComment}
                    onChange={this.handleDeliveryComment}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Divider section hidden/>

          <Grid>
            <Grid.Column>
              <Header as="h1" textAlign="center">Metoda płatności</Header>
              <Form id='payMethod'>

                {_.map(this.state.order['payment_methods'], ({provider}, i: number) => (
                  <Form.Field key={i}>
                    <Radio value={t(provider)}
                           label={dict[provider]}
                           checked={this.state.paymentMethod === provider}
                           onClick={() => this.setState({paymentMethod: provider})}
                    >
                    </Radio>
                  </Form.Field>
                ))}
              </Form>

            </Grid.Column>

          </Grid>
          <Divider section hidden/>
          <Header as="h1" textAlign="center">Twoje zamówienie</Header>
          <Divider section hidden/>
          <Grid>
            <Grid.Row>
              <Grid.Column width={4}/>
              <Grid.Column width={4}>
                <Image src={box} alt="Suplementy" floated="right"/>
              </Grid.Column>
              <Grid.Column width={4} textAlign="left">
                <p>Pure4me</p>
                {_.map(this.state.order['orderLines'], ({name}, i: number) => (
                  <React.Fragment key={i}>
                    <span>
                      {name} {(i < this.state.order['orderLines'].length - 1) && "• "}
                    </span>
                  </React.Fragment>
                ))}
                <Divider hidden/>
                <div style={{cursor: "pointer"}} onClick={this.getDocument.bind(this)}>Wyświetl szczegółowe informacje o produkcie <Image src={arrow_right} alt="Szczegóły" id="img-more"/></div>
              </Grid.Column>
              <Grid.Column width={4}/>
            </Grid.Row>
            <Divider hidden/>
            <Grid.Row columns={3} divided>
              <Grid.Column textAlign="center">
                Kupon rabatowy: <br/>
                {this.discountValue()} zł
              </Grid.Column>
              <Grid.Column textAlign="center">
                Wysyłka: <br/>
                Kurier DPD {this.findValue('price_delivery')} zł
              </Grid.Column>
              <Grid.Column textAlign="center">
                Podsumowanie: <br/>
                {this.findValue('price_full')} zł
              </Grid.Column>
            </Grid.Row>
            <Divider hidden/>
            <Grid.Row columns={3} verticalAlign='middle'>
              <Grid.Column textAlign="right">Kod rabatowy:</Grid.Column>
              <Grid.Column textAlign="left">
                <Input type="text" placeholder="kod rabatowy" fluid value={this.state.value}
                       onChange={this.handleInputChange.bind(this)}/>
              </Grid.Column>
              <Grid.Column textAlign="left">
                <Button primary floated="left" id="btn-clear" onClick={this.sendPromo.bind(this)} disabled={this.state.promoActive}>
                  zastosuj kod</Button>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Button primary floated="left" id="btn-clear" onClick={this.orderRedirect}>cofnij</Button>
                {this.displayButton()}
              </Grid.Column>
            </Grid.Row>
            <Divider hidden/>
          </Grid>
          <Divider hidden/>
        </Container>
      </Segment>
    );
  }

  render() {
    if(this.state.order) return this.yourorder();
    if(this.state.loading) return <Segment basic loading/>
    return (
      <Segment basic style={{minHeight: "calc(100vh - 10px)", paddingTop: "150px"}}>
        <Container textAlign='center'>
          <Header as='h1'>Coś jest nie tak z zamówieniem</Header>
          <Header as='h3'>Zamówienie nie mogło zostać wczytane, sprawdź czy wszystko się zgadza</Header>
          <Button primary onClick={this.orderRedirect}>Popraw zamówienie</Button>
        </Container>
      </Segment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(YourOrder);