import React, {Component} from 'react';
import {Button, Container, Divider, Form, Grid, Header, Input, Message} from "semantic-ui-react";
import * as actions from "../../actions";
import {connect} from "react-redux";

interface StateProps {
  getAddresses: any
  addOrderAddress: any
  address: any
}

export function mapStateToProps(state: any): StateProps {
  return {
    getAddresses: state.getAddressesFormData,
    address: state.addOrderAddress,
  };
}

interface DispatchProps {
  collect: typeof actions.collectAddressesFormData,
  addOrderAddress: typeof actions.addOrderAddress,

}

const mapDispatchToProps = {
  collect: actions.collectAddressesFormData,
  addOrderAddress: actions.addOrderAddress,
};

interface OwnProps {
  id?: string,
  name: string,
  header: string,
  disabled: boolean
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  firstName: string,
  lastName: string,
  companyName: string,
  street: string,
  streetNumber: string,
  zipCode: string,
  city: string,
  phone: string,
  email: string,
  addressDone: boolean,

}

class Address extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      companyName: "",
      street: "",
      streetNumber: "",
      zipCode: "",
      city: "",
      phone: "",
      email: "",
      addressDone: false,
    }
  }

  putDbAddressIntoForm = () => {
    const dbAddress = this.props.dbAddress;
    if (dbAddress){
      this.setState({
        firstName: dbAddress.name,
        lastName: dbAddress.surname,
        street: dbAddress.street,
        streetNumber: dbAddress.street_number,
        zipCode: dbAddress.zip_code,
        city: dbAddress.city,
        phone: dbAddress.phone,
        email: dbAddress.email
      })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevProps.dbAddress !== this.props.dbAddress){
      this.putDbAddressIntoForm()
    }
    if (prevProps.getAddresses !== this.props.getAddresses) {
      if (this.props.getAddresses) {
        let data = this.state;
        this.props.collect({[this.props.name]: data});
      }
    }
    if (prevProps.address !== this.props.address) {
      if (this.props.address.data) {
        this.setState({
          addressDone: true,
        })
        this.props.isFinished(true);
      }
    }
    if (prevState !== this.state) {
      this.props.getHelperAddress(this.copyAddressToInvoice())
    }

  }

  copyAddressToInvoice() {
    return {
      city: this.state.city,
      zipCode: this.state.zipCode,
      street: this.state.street,
      streetNumber: this.state.streetNumber,
      phone: this.state.phone,
      name: this.state.companyName,
      email: this.state.email,
    };
  }

  handleValueChange(e, {name, value}) {
    this.setState({[name]: value})
  }

  setAddress(e) {
    e.preventDefault();
    let formData = {
      use_client: false,
      country: 'Poland',
      city: this.state.city,
      zip_code: this.state.zipCode,
      street: this.state.street,
      street_number: this.state.streetNumber,
      phone: this.state.phone,
      name: this.state.firstName,
      surname: this.state.lastName,
      email: this.state.email,
      code: this.props.code
    }
    this.props.addOrderAddress(formData)
  }

  editAddress = () => {
    this.setState({
      addressDone: false,
    })
    this.props.isFinished(false);
  }

  handleOnlyNumbers = (e, {name, value}) => {
    if (value.includes("-")) {
      value = value.replace("-", "");
    }
    if (isNaN(value)) {
      return
    }
    if (value.length <= 5) {
      this.handleValueChange(e, {name, value})
    }
  }

  showBetterZipCode = (value) => {
    let array = value.split("");
    if (value.length >= 3) array.splice(2, 0, "-");
    return array.join("");
  }

  render() {
    if (!this.state.addressDone) {
      return (
        <>
          <Header as="h1" textAlign="center" disabled={this.props.disabled}>{this.props.header}</Header>
          <Message>
            <p>Aby chronić środowisko, prześlemy Ci Twój dowód zakupu w formie elektronicznej na podany poniżej email</p>
          </Message>
          <Divider hidden/>
          <Form id={this.props.id} onSubmit={this.setAddress.bind(this)}>
            <Form.Group widths='equal'>
              <Form.Field
                name={"firstName"}
                value={this.state.firstName}
                required
                control={Input}
                label='Imię'
                placeholder='Imię'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
              <Form.Field
                name={"lastName"}
                value={this.state.lastName}
                required
                control={Input}
                label='Nazwisko'
                placeholder='Nazwisko'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
            </Form.Group>
            <Form.Field
              name={"companyName"}
              value={this.state.companyName}
              control={Input}
              label='Nazwa firmy'
              placeholder='Nazwa firmy'
              onChange={this.handleValueChange.bind(this)}
              disabled={this.props.disabled}
            />
            <Form.Group>
              <Form.Field width={12}
                          name={"street"}
                          value={this.state.street}
                          required
                          control={Input}
                          label='Adres'
                          placeholder='Adres'
                          onChange={this.handleValueChange.bind(this)}
                          disabled={this.props.disabled}
              />
              <Form.Field width={4}
                          name={"streetNumber"}
                          value={this.state.streetNumber}
                          required
                          control={Input}
                          label='Nr domu'
                          placeholder='Nr domu'
                          onChange={this.handleValueChange.bind(this)}
                          disabled={this.props.disabled}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field
                name={"zipCode"}
                value={this.showBetterZipCode(this.state.zipCode)}
                required
                control={Input}
                label='Kod pocztowy'
                placeholder='Kod pocztowy'
                onChange={this.handleOnlyNumbers.bind(this)}
                disabled={this.props.disabled}
              />
              <Form.Field
                name={"city"}
                value={this.state.city}
                required
                control={Input}
                label='Miasto'
                placeholder='Miasto'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field
                name={"phone"}
                value={this.state.phone}
                required
                control={Input}
                label='Numer telefonu'
                placeholder='Numer telefonu'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
              <Form.Field
                name={"email"}
                value={this.state.email}
                required
                control={Input}
                label='Adres e-mail'
                placeholder='Adres e-mail'
                onChange={this.handleValueChange.bind(this)}
                disabled={this.props.disabled}
              />
            </Form.Group>
            <Grid textAlign='center'>
              <Grid.Column>
                <Button primary className='shadow-light' id='btn-address'>Ustaw adres</Button>
              </Grid.Column>
            </Grid>
          </Form>
        </>
      );
    } else {
      const data = this.props.address.data.address
      return <div>
        <Header as="h1" textAlign="center" disabled={this.props.disabled}>{this.props.header}</Header>
        <Divider hidden/>
        <Container>
          <Grid celled>
            <Grid.Row columns={3}>
              <Grid.Column>Imię:</Grid.Column>
              <Grid.Column>{data.name}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Nazwisko:</Grid.Column>
              <Grid.Column>{data.surname}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Ulica:</Grid.Column>
              <Grid.Column>{data.street}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Numer domu / lokalu:</Grid.Column>
              <Grid.Column>{data.street_number}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Kod pocztowy:</Grid.Column>
              <Grid.Column>{this.showBetterZipCode(data.zip_code)}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Miasto:</Grid.Column>
              <Grid.Column>{data.city}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Kraj:</Grid.Column>
              <Grid.Column>{data.country}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Email:</Grid.Column>
              <Grid.Column>{data.email}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={3}>
              <Grid.Column>Telefon:</Grid.Column>
              <Grid.Column>{data.phone}</Grid.Column>
            </Grid.Row>
          </Grid>
          <Button onClick={() => this.editAddress()} floated="right">edytuj adres</Button>
        </Container>
      </div>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Address);
